import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getSim, setErrorCb } from './common/utils'
import { useLogin } from './common/LoginContext'
import LoadingErrorPage from './common/LoadingErrorPage'
import CustomerSupportFab from './common/CustomerSupportFab'
import { AppHeader } from './AppHeader'
import { OrderBuilderPanel } from './OrderBuilderPanel'
import { ContestRegistrationPanel } from './ContestRegistrationPanel'

const useStyles = makeStyles(theme => ({
  appRoot: {
    height: '100vh',
    width: '100vw',
    textAlign: 'center',
    backgroundColor: theme.palette.custom.tertiary,
  },
  bodyContainer: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    gap: 20,
  },
  emptyContent: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    height:'80%',
  },
}))

function App() {
  const classes = useStyles()
  const [errorState, setErrorState] = React.useState(false)
  const [userAuthenticated, setUserAuthenticated] = React.useState(null)
  const [loginState] = useLogin()
  const [openPage, setOpenPage] = React.useState('builder')

  setErrorCb(()=>setErrorState(true))

  React.useEffect(() => {
    const authenticateUser = async (user) => {
      const resp = await getSim('verifyUserAuth', user)
      if (resp.status === 200){
        setUserAuthenticated(true)
      }
    }
    if (loginState?.user){
      authenticateUser(loginState?.user)
    } else {
      setUserAuthenticated(false)
    }
  }, [loginState])

  return (
    <>
      {errorState ? <LoadingErrorPage />:
        <div className={classes.appRoot}>
          <CssBaseline />
          <AppHeader
            userAuthenticated={userAuthenticated}
            openPage={openPage}
            setOpenPage={setOpenPage}
          />
          {userAuthenticated ?
            <div className={classes.bodyContainer}>
              {openPage === 'builder' ? <OrderBuilderPanel/>:null}
              {openPage === 'contest' ? <ContestRegistrationPanel />:null}
            </div>:
            <div className={classes.emptyContent}>
              {'There isn\'t anything here!'}
            </div> }
          <CustomerSupportFab />
        </div>
      }
    </>
  )
}

export default App
