import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { useMissionPacks } from './MissionPackContext'
import IconButton from '@material-ui/core/IconButton'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'

const useStyles = makeStyles(theme => ({
  paper: {
    // overflow: 'auto',
    width:'90%',
    height:'90%',
    backgroundColor: theme.palette.custom.primary,
  },
  dialog: {
    height: '65%',
  },
  dialogContent: {
    padding: '20px',
  },
  skuCardPaper: {
    backgroundColor: theme.palette.custom.skuCard.body,
  },
  skuCardFooter: {
    backgroundColor: theme.palette.custom.skuCard.footer,
  },
  skuCardHeader: {
    backgroundColor: theme.palette.custom.skuCard.header,
  },
}))

export const Cell = withStyles(theme => ({
  root: {
    fontSize: 14,
    height:37,
    lineHeight: '16px',
  },
}))(TableCell)

const LICENSE_OPTIONS = {
  '00': () => {
    return {
      expires: false,
      type: 'Floating',
      term:0,
    }
  },
  '01': () => {
    return {
      expires: true,
      type: 'Floating',
      term:0,
    }
  },
  '02': () => {
    return {
      expires: false,
      type: 'Assigned',
      term:0,
    }
  },
  '03': () => {
    return {
      expires: true,
      type: 'Assigned',
      term:0,
    }
  },
}

const decodeSku = (sku, productsInfo) => {
  const products = []
  if (!sku || sku.length < 3) {
    console.log('early termination')
    return products
  }

  let skuVal = sku
  while (skuVal.length >= 3) {
    const skuCode = skuVal.substring(0, 3)
    const productInfo = productsInfo.find(el => el.id === skuCode.toUpperCase())
    let skuLeftNoCode = skuVal.substring(3)
    let skuQty = ''
    let licenseOptions = LICENSE_OPTIONS['00']()

    while (skuLeftNoCode.match('^[0-9].*$')) {
      skuQty += skuLeftNoCode.substring(0,1)
      skuLeftNoCode = skuLeftNoCode.substring(1)
    }

    // If the product has a special D code
    if ((skuLeftNoCode.length === 1 && skuLeftNoCode === 'D') || skuLeftNoCode.substring(0,1) === 'D'){
      const skuOptions = skuLeftNoCode.substring(1,3)
      skuLeftNoCode = skuLeftNoCode.substring(3)
      licenseOptions = LICENSE_OPTIONS[skuOptions]()
    }

    if (licenseOptions.expires){
      let term = ''
      while (skuLeftNoCode.match('^[0-9].*$')) {
        term += skuLeftNoCode.substring(0,1)
        skuLeftNoCode = skuLeftNoCode.substring(1)
      }

      licenseOptions.term = parseInt(term, 10)
      if (term === ''){
        licenseOptions.term = 365
      }
    }

    skuVal = skuLeftNoCode
    // if there were no numbers behind the 3 character code it means qty = 1
    if (skuQty === '') {
      skuQty = '1'
    }
    if (!productInfo || parseInt(skuQty, 10) === 0) {
      continue
    }

    const product = {
      name: productInfo.title,
      type: 'license',
      curriculumId: productInfo.id,
      licenseOptions: licenseOptions,
      quantity: parseInt(skuQty, 10),
    }

    products.push(product)
  }

  return products
}

export const skuItemsToProducts = (skuItems, packList) => {
  return skuItems.map((skuItem)=> {
    // Shopify uses code as the skuItems key, our db uses skuCode, this translates the two
    // in the ui
    var sku = skuItem.sku
    if ('skuCode' in skuItem){
      sku = skuItem.skuCode
    }
    return {products: decodeSku(sku, packList), quantity:skuItem.quantity}
  })
}

export const SKUCard = (props) => {
  const classes = useStyles()
  const { missionPacks } = useMissionPacks()

  return (
    <TableContainer square component={Paper} style={{maxHeight: props.height ?? null}}>
      <Table size='small' stickyHeader>
        <TableHead >
          <TableRow className={classes.skuCardHeader} style={{fontSize: 14, height:37}}>
            <Cell >Product</Cell>
            <Cell >License Type</Cell>
            <Cell >License Term</Cell>
            <Cell >Seats</Cell>
            {props.handleDelete ?
              <Cell >Delete Product</Cell>:
              null
            }
          </TableRow>
        </TableHead>
        <TableBody >
          {props.skuItems.map((row, index) => {
            const decodedSku = decodeSku(row.sku ?? row.skuCode, missionPacks)[0]
            if (!decodedSku?.name){
              return null
            }
            return (
              <TableRow key={index} className={classes.skuCardPaper}>
                <Cell >{decodedSku.name}</Cell>
                <Cell >{decodedSku.licenseOptions.type}</Cell>
                <Cell >{decodedSku.licenseOptions.term === 0 ? '-' : decodedSku.licenseOptions.term }</Cell>
                <Cell >{row.quantity}</Cell>
                {props.handleDelete ?
                  <Cell style={{padding: 0}}>
                    <IconButton style={{height: 40, width: '100%', borderRadius: 0}} onClick={() => props.handleDelete(index)}>
                      <DeleteForeverIcon style={{color: '#E64C43', padding: 0}}/>
                    </IconButton>
                  </Cell>:
                  null
                }
              </TableRow>
            )
          })}

        </TableBody>
      </Table>
    </TableContainer>
  )
}