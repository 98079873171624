import React from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { RespButton } from './common/buttons'
import { postSim } from './common/utils'
import { useLogin } from './common/LoginContext'
import { OutlinedTextField } from './common/fields'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import ListIcon from '@material-ui/icons/List'

function LicenseUpdaterDialog({ open, handleClose }) {
  const [loginState] = useLogin()
  const [text, setText] = React.useState('')
  const [fetchStatus, setFetchStatus] = React.useState(null)

  const handleUpdateLicenseToPerpetual = async () => {
    return await postSim('updateLicenseToPerpetual', loginState?.user, {
      licenseId: text,
    })
  }

  const onSuccess = () => {
    setText('')
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <div style={{padding: 15, width: 400}}>
        <Typography style={{paddingBottom: 15}}>Turns an expiration license into a perpetual one.</Typography>
        <OutlinedTextField
          style={{width: '100%'}}
          label={'License Id'}
          onChange={ev=>setText(ev.target.value)}
          value={text}
        />
        <div style={{paddingTop: 15, display: 'flex', justifyContent: 'flex-end'}}>
          <RespButton
            buttonComponent={Button}
            onClick={handleUpdateLicenseToPerpetual}
            successCb={onSuccess}
            status={fetchStatus}
            setStatus={setFetchStatus}
            variant='contained'
          >
            Change License Term
          </RespButton>
        </div>
      </div>
    </Dialog>
  )
}

function LicenseUpdaterButton() {
  const [open, setOpen] = React.useState(false)
  return (
    <>
      <IconButton
        style={{marginLeft: 15}}
        onClick={() => setOpen(true)}
      >
        <ListIcon style={{color: 'black'}}/>
      </IconButton>
      <LicenseUpdaterDialog open={open} handleClose={() => setOpen(false)}/>
    </>
  )
}

export { LicenseUpdaterButton }
export default LicenseUpdaterDialog
