import React from 'react'
import { putSim } from './common/utils'
import { useLogin } from './common/LoginContext'
import { CircularProgress } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import * as XLSX from 'xlsx'

const CSVSelector = ({ onChange }) => {
  const handleFileChange = async (e) => {
    if (e.target.files) {
      try {
        const file = e.target.files[0]

        const reader = new FileReader()
        reader.onload = (evt) => { // evt = on_file_select event
          /* Parse data */
          const bstr = evt.target.result
          const wb = XLSX.read(bstr, {type:'binary'})
          onChange(Object.values(wb.Sheets)[0])
        }
        reader.readAsBinaryString(file)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return <input style={{width: 300}} type='file' accept='.xlsx' onChange={handleFileChange} />
}

const formatXLSXFile = (fileContent) => {
  const entries = {}
  delete fileContent['!ref']
  Object.entries(fileContent).forEach(([key, val]) => {
    const rowLetter = (key).replace(/\d+/g,'')
    const rowNumber = (key).replace(/\D/g, '')
    if (!(rowNumber in entries)) {
      entries[rowNumber] = {}
    }

    entries[rowNumber][rowLetter] = val.w
  })

  const valid = !!Object.values(entries[1]).find(val => val === 'Terms and Conditions: \nI have read and agree to all rules, terms and conditions to compete in this event.')
  if (!valid) {
    throw new Error('Couldn\'t find terms and conditions field. Is this a valid excel sheet?')
  }

  const fieldsLength = Object.keys(entries[1]).length
  delete entries[1]
  if (fieldsLength === 9) {
    return Object.values(entries).map((entry) => {
      return {
        email: entry.B,
        name: entry.F,
        bandId: 'indy',
        registrationDetails: entry,
      }
    })
  }
  if (fieldsLength === 13) {
    return Object.values(entries).map((entry) => {
      return {
        email: entry.B,
        name: entry.J,
        bandId: `${entry.H}${entry.I === 'Yes' ? '_girlswhocode' : ''}`,
        registrationDetails: entry,
      }
    })
  }

  throw new Error('File is neither a collection of individual or group registrations.')
}


function ContestRegistrationPanel() {
  const [loginState] = useLogin()
  const [resp, setResp] = React.useState(null)
  const [err, setErr] = React.useState('')
  const [registrationsAdded, setRegistrationsAdded] = React.useState(null)
  const [totalChunks, setTotalChunks] = React.useState(null)
  const [chunksCompleted, setChunksCompleted] = React.useState(null)

  React.useEffect(() => {
    if (resp !== 'failed') {
      setErr('')
    }
    if (resp !== 'success') {
      setRegistrationsAdded(null)
    }
  }, [resp])

  const postRegistrationEntriesChunk = async (chunk) => {
    try {
      const resp = await putSim('contests/registrations', loginState?.user, { registrations: chunk })
      return (await resp.json()).filter(x => !!x).length
    } catch {
      //
    }
  }

  const postRegistrationEntries = async (fileContent) => {
    try {
      setResp('waiting')
      const registrations = formatXLSXFile(fileContent).filter(registration => !!registration?.email)
      let chunkSize = 10
      let chunks = Array.from({length: Math.ceil(registrations.length / chunkSize)}, (_, i) =>
        registrations.slice(i * chunkSize, i * chunkSize + chunkSize)
      )

      setTotalChunks(chunks.length * chunkSize)
      let totalAdded = 0
      for (const i in chunks) {
        console.log(chunks[i])
        const resp = await postRegistrationEntriesChunk(chunks[i])
        if (resp) {
          totalAdded += resp
        }
        if (i % 10 === 0) {
          setChunksCompleted(totalAdded)
        }
      }
      setResp('success')
      setRegistrationsAdded(totalAdded)
    } catch (err) {
      setResp('failed')
      console.error(err)
      setErr(err)
    }
  }

  return (
    <div id='drop-area' style={{width: '100%', height: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <CSVSelector onChange={postRegistrationEntries}/>
      {resp === 'waiting' ? <div>
        <CircularProgress size={24}/>
        <Typography style={{paddingTop: 10}}>{`Processed ${chunksCompleted}/${totalChunks} chunks.`}</Typography>
      </div>:null}
      {resp === 'success' ? <CheckIcon style={{color: 'green'}}/>:null}
      {resp === 'failed' ? <ClearIcon style={{color: 'red'}}/>:null}
      {registrationsAdded !== null && registrationsAdded !== 0 ? <Typography>{`Successfully added ${registrationsAdded} registrations.`}</Typography>:null}
      {registrationsAdded === 0 ? <Typography>{'Found no new registrations to add.'}</Typography>:null}
      <Typography>{err}</Typography>
    </div>
  )
}

export { ContestRegistrationPanel }
