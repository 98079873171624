import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import { LoginProvider } from './common/LoginContext'
import { MissionPackProvider } from './common/MissionPackContext'
import { SnackbarProvider } from 'notistack'

var mode = 'light'

const darkModeColors = {
  primary: '#0E1D31',
  secondary: '#0E1D31',
  tertiary: '#F9F9F9',
  quaternary: '#ffffff',
  accent: '#EE4865',
  outlinedTextField: {
    disabled: {
      label: '#9B9B9B',
    },
    unfocused: {
      label: 'black',
      border: 'black',
    },
    focused: {
      label: 'black',
      border: 'black',
    },
    hovered: {
      label: 'black',
      border: 'black',
    },
  },
  skuCard: {
    body: '#C8C8C8',
    footer: '#A2A2A2',
    text: 'black',
  },
}

const lightModeColors = {
  primary: 'grey',
  secondary: '#0B3C5D',
  tertiary: '#F5F5F5',
  quaternary: '#000000',
  accent: '#D9B40E',
  outlinedTextField: {
    disabled: {
      label: '#9B9B9B',
    },
    unfocused: {
      label: 'black',
      border: 'black',
    },
    focused: {
      label: 'black',
      border: 'black',
    },
    hovered: {
      label: 'black',
      border: 'black',
    },
  },
  skuCard: {
    body: '#C8C8C8',
    footer: '#A2A2A2',
    text: 'black',
  },
}

var colors = mode === 'dark' ? darkModeColors : lightModeColors

const theme = createTheme({
  palette: {
    type: mode,
    primary: {
      main: colors.quaternary,
    },
    secondary: {
      main: '#bf9e0b',
    },
    custom: colors,
    // text:{
    //   primary: '#a5a58d',
    // },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <SnackbarProvider>
      <MissionPackProvider filteredPackIds={['Free', 'Free_Demo']}>
        <LoginProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </LoginProvider>
      </MissionPackProvider>
    </SnackbarProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
